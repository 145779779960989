import React from "react";
import Image from "../ui/Image/Image";
import ImageGroup from "../ui/Image/ImageGroup";

const WaterMillStoneDetails = () => {
	return (
		<div>
			<h1>Millstone Marble &amp; Tile</h1>
			<section>
				<h2>Logo</h2>
				<p>
					<Image
						src="images/projects/millstone/millstone_logo_reference.jpg"
						align="right"
						caption="An English millstone"
						alt="An antique millstone leaning against a barn"
					/>
					The client came to me looking for a full rebranding. We started with
					the logo since it's a great way to discuss the general tone of the
					entire project including color palate, mood, and general branding
					goals.
				</p>
				<p>
					One of the first ideas the client had was basing the logo on a literal
					"millstone"… a stone (utilized in pairs) to grind something, usually
					grain.
				</p>
				<div className="clear" />
				<p>
					<Image
						src="images/projects/millstone/millstone_logo_geometry.jpg"
						align="right"
						caption="Final logo geometry"
						alt="The final logo with additional lines to show the geometry of the design"
					/>
					While we discussed a few other direction, we were both drawn in this
					direction because of how visually interesting the tool is and how well
					it lent itself to a striking design. The client was initially
					concerned that most people don't know what a “mill stone” is or looks
					like, but I assured him that many famous logos are even more abstract,
					and it could make a good conversation starter.
				</p>
				<p>
					After approval, I presented the logo with some usage suggestions to
					help the client better see the final vision.
				</p>
				<div className="clear" />
				<Image
					src="images/projects/millstone/millstone_logo_mockup.jpg"
					caption="Final logo render"
					alt="The final logo rendered on textured card stock"
				/>
			</section>
			<section>
				<h2>Website</h2>
				<p>
					With the logo, base color, and basic typography established with the
					original logo design, I already had most of my broad stroke questions
					answered. The main features the client wanted included in the final
					design was a blog and a photo gallery. We decided build the site in
					Wordpress as it's a platform I'm familiar with, and it's got great
					support, and very well suited as a blogging platform. As for the
					gallery, I utilized a plugin that displays an Instagram feed to
					minimize how many platforms the client would have to update. As any
					modern site, it's also fully responsive and works perfectly on devices
					of all sizes.
				</p>
				<ImageGroup>
					<Image
						src="images/projects/millstone/millstone_website_desktop.jpg"
						caption="Desktop"
						alt="A screenshot of the website as it would appear on a typical desktop sized screen"
					/>
					<Image
						src="images/projects/millstone/millstone_website_mobile.jpg"
						caption="Mobile"
						alt="A screenshot of the website as it would appear on a typical desktop sized screen"
					/>
				</ImageGroup>
			</section>
		</div>
	);
};

export default WaterMillStoneDetails;
